
import { Component, Vue } from 'vue-property-decorator'
import { store } from '@/store'
import { dispatchKwikCheckLoggedIn } from 'kwik-vue/store/main/actions'
import { readKwikIsLoggedIn } from 'kwik-vue/store/main/getters'
import { RouteNames } from '@/router/names'
import { KwikRouteNames } from 'kwik-vue/router/names'

const startRouteGuard = async (to: any, from: any, next: any) => {
  await dispatchKwikCheckLoggedIn(store)
  if (readKwikIsLoggedIn(store)) {
    console.log(to.name)
    if (to.name === KwikRouteNames.LOGIN || to.path === '/') {
      next({ name: RouteNames.FF_DASHBOARD })
    } else {
      next()
    }
  } else {
    console.log(to.name)
    if ((to.name === KwikRouteNames.RECOVER_PASSWORD) || (to.name === KwikRouteNames.RESET_PASSWORD) || (to.name === KwikRouteNames.LOGIN)) {
      next()
    } else {
      next({ name: KwikRouteNames.LOGIN })
    }
  }
}

@Component
export default class Start extends Vue {
  public beforeRouteEnter (to: any, from: any, next: any) {
    startRouteGuard(to, from, next)
  }

  public beforeRouteUpdate (to: any, from: any, next: any) {
    startRouteGuard(to, from, next)
  }
}
